.loader_div {
  stroke-width: 3;
  stroke: aqua;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  stroke-dasharray: 420.7037353515625px;
  stroke-dashoffset: 420.7037353515625px;
  /* animation: ani .5s forwards ease-out; */  
}
.loader {
  position: absolute;
  bottom: 30px;
}

@keyframes ani {
  to {
    stroke-dashoffset: 0px;
  }
}

@keyframes hide {
  to {
    stroke-dashoffset: 420.7037353515625px;
  }
}
.o {
  position: relative;
  width: 100px;
}
.b {
  height: 3px;
  width: 100px;
  background-color: #3f3f3f;
  /* filter: blur(.4px) drop-shadow(0px 0px 8px aqua); */
  /* animation: spin forwards 14s linear; */
}
@keyframes spin {
  from {width: 0px;}to {width: 100px;}
}
